import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import LogoSvg from '../assets/icons/logo/logo.svg';
import Button from 'pages/start/results/components/StartNowButton';
import { Text } from 'components';
import SocialRow from './SocialRow';
import { useRouter } from 'apis/history';
import { mobile, tablet, useQuery } from 'styles/breakpoints';
import theme from 'utils/theme';

const Footer = ({ isMobileLink = false }: { isMobileLink?: boolean }) => {
  const {
    goToPrivacy,
    goToPrivacyMobile,
    goToTerms,
    goToTermsMobile,
    goToLanding,
    goToStory,
    goToReviews,
    goToFAQ,
    goToContact,
    goToContactMobile,
    goToStarterGuide,
  } = useRouter();
  const { isMobile } = useQuery();

  return (
    <FooterWrap>
      <FooterContainer>
        <FooterRow>
          {/* {!isMobileLink && (
            <FooterCol>
              <LinksTitle>Company</LinksTitle>

              <Links>
                <LinkWrap onClick={() => goToFAQ()}>
                  <Link>FAQ</Link>
                </LinkWrap>
                <LinkWrap onClick={() => goToStarterGuide()}>
                  <Link>Started Guide</Link>
                </LinkWrap>
                <LinkWrap
                  onClick={() =>
                    isMobileLink ? goToContactMobile() : goToContact()
                  }
                >
                  <Link>Contact Us</Link>
                </LinkWrap>
              </Links>
            </FooterCol>
          )} */}
          {/* {!isMobileLink && (
            <FooterColRight>
              <ButtonTitle>Build relationship with your pet</ButtonTitle>
              <Button color="orange" onClick={() => goToLanding()}>
                TAKE THE QUIZ
              </Button>
            </FooterColRight>
          )} */}
        </FooterRow>
        <FooterCopy>
          <CopyLeft>
            <CopyLeftItem>
              <CopyItemLink>
                <PaddingWrap>
                  <LogoLinkContainer
                    onClick={() => !isMobileLink && goToLanding()}
                  >
                    <LogoIcon />
                    <StyledText>Nova Health.</StyledText>
                  </LogoLinkContainer>
                  <StyledText>
                    © {new Date().getFullYear()}. All rights reserved
                  </StyledText>
                </PaddingWrap>
              </CopyItemLink>
            </CopyLeftItem>
            {isMobile ? null : <Dot />}
            <>
              <CopyLeftItem>
                <CopyItemLink
                  onClick={() =>
                    isMobileLink ? goToTermsMobile() : goToTerms()
                  }
                >
                  <StyledText>Terms & Conditions</StyledText>
                </CopyItemLink>
              </CopyLeftItem>
              <Dot />
              <CopyLeftItem>
                <CopyItemLink
                  onClick={() =>
                    isMobileLink ? goToPrivacyMobile() : goToPrivacy()
                  }
                >
                  <StyledText>Privacy Policy</StyledText>
                </CopyItemLink>
              </CopyLeftItem>
            </>
          </CopyLeft>
          {!isMobileLink && <SocialRow />}
        </FooterCopy>
      </FooterContainer>
    </FooterWrap>
  );
};

export default Footer;

const PaddingWrap = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  @media ${tablet} {
    padding-bottom: 1rem;
    padding-top: 1rem;
    padding-right: 10px;
  }
`;

const StyledText = styled(Text)`
  font-size: 0.875rem;
  font-weight: 400;
  color: ${theme.colors.dark60};
  word-wrap: break-word;
  white-space: nowrap;
`;

const Dot = styled.div`
  background: ${theme.colors.dark60};
  border-radius: 50%;
  content: '';
  display: block;
  height: 2px;
  margin: 0 17px;
  width: 2px;
`;

const LogoIcon = styled(LogoSvg)`
  height: 20px;
  margin-right: 12px;
  width: 20.5px;
`;

const CopyItemLink = styled.div`
  color: inherit;
  text-decoration: none;
  transition: all 0.3s ease;
  cursor: pointer;
  display: flex;
  align-items: center;
`;

const CopyLeftItem = styled.li`
  align-items: center;
  display: flex;
`;

const CopyLeft = styled.ul`
display: flex;
flex-direction: row;
flex-wrap: wrap;
align-items: center;
  font-size: 14px;
  letter-spacing: -0.024em;
  line-height: 140%;
  list-style: none;
}
`;

const ButtonTitle = styled(Text)`
  margin-bottom: 1.5rem;
  color: #1c1c28;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: -0.024em;
  line-height: 140%;
  font-family: Inter;
  @media ${tablet} {
    font-weight: 600;
  }
`;

const Link = styled.a`
  font-family: Inter;
  cursor: pointer;
  color: inherit;
  text-decoration: none;
  transition: all 0.3s ease;
`;

const LinkWrap = styled.li`
  list-style: none;
  margin-right: 32px;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: -0.024em;
  line-height: 140%;
  @media ${tablet} {
    margin-bottom: 24px;
  }
`;

const Links = styled.ul`
  display: flex;
  flex-wrap: wrap;
  margin: 13px 0;
  @media ${tablet} {
    flex-direction: column;
  }
`;

const LinksTitle = styled(Text)`
  margin-bottom: 1.5rem;
  font-weight: 600;
`;

const FooterRow = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`;

const FooterCopy = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 3rem;
  border-top: 1px solid ${theme.colors.light100};
  @media ${tablet} {
    flex-direction: column-reverse;
  }
`;

const FooterCol = styled.div`
  width: 50%;
`;

const FooterColRight = styled.div`
  margin-left: auto;
  min-width: 330px;
  width: 29%;
  @media ${tablet} {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
`;

const FooterWrap = styled.footer`
  display: block;
  background: #fbfbff;
  border-top: 1px solid ${theme.colors.light60};
  @media ${tablet} {
  }
`;

const FooterContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: auto;
  gap: 3rem;
  width: 100%;
  padding: 3rem 32px;
  max-width: 1140px;
  @media ${tablet} {
    padding-left: 16px;
    padding-right: 16px;
  }
`;

const LogoLinkContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
`;
